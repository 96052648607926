header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    display: grid;
    align-items: center;
}

.header__grid__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 100% 1fr;
    gap: 5%;
    width: 100%;
    height: 80%;
}

/* CTA */

.CTA {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* SOCIALS */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 2.3rem;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* Image */

.me__container {
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding: 1rem 1rem 1rem 1rem;

}

.me_image {
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    top: 50%;
    height: 100%;
    min-height: 400px;
    border : 2px solid transparent;
        -webkit-transition : border 500ms ease-out;
    -moz-transition : border 500ms ease-out;
    -o-transition : border 500ms ease-out;
    transition : border 500ms ease-out;
}

/* .me_image:hover {
    border : 2px solid var(--color-primary);   
} */

/* ScrollDown */

.scroll__down {
    position: absolute;
    right: -0rem;
    bottom: 5.5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* MEDIA QUERIES (MEDIUM) */

@media screen and (max-width: 1024px) {

    header {
        padding-top: 2rem;
    }

    .header__grid__container {
        grid-template-columns: 1fr;
        grid-template-rows: 55% 55%;
        gap: 0;
        padding-bottom: 1rem;
    } 

    .me_image {
        height: 100%;
        min-height: 0px;
    }

    .me {
        padding: 6rem 6rem 6rem 6rem;
        height: 100%;
    }

    .CTA {
        margin-top: 1.5rem;
    }
}

/* MEDIA QUERIES (SMALL) */

@media screen and (max-width: 600px) {
    .header__container {
        padding-top: 0;
    }

    header {
        padding-top: 2rem;
    }

    .header__grid__container {
        grid-template-columns: 1fr;
        grid-template-rows: 55% 55%;
        gap: 5%;
        padding-bottom: 1rem;
    } 

    .header__socials {
        display: flex;
        width: 100%;
        flex-direction: row;
        position: center;
        left: 0;
        gap: 2rem;
        justify-content: center;
            }

    .header__socials::after {
        display: none;
    }

    .scroll__down {
        display: none;
    }

    .CTA {
        margin-top: 1.5rem;
    }
}