.markov__container_top {
    height: 100%
}

.markov__container {
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 5%;
    position: relative;
    padding-top: 10rem;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg,
            transparent,
            var(--color-primary),
            transparent);
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.markov__screenshots {
    width: 300px;
    height: 650px;
}

.markov__screenshots img {
    height: 90%;
    width: auto;
}

p {
    text-align: left;
}

g {
    font-size: 1.75rem;
    color:lightskyblue;
    font-weight: 500;
}

b {
    font-size: 2.4rem;
    color: lightskyblue;
    font-weight: 500;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 0.8rem 1rem 0.5rem;
    text-align: center;
    transition: var(--transition);
    opacity: 1;
}

.about__card:hover {
    background: var(--color-bg-variant);
    border-color: var(--color-primary-variant);
    cursor: default;
    opacity: 0.5;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 0.2rem;
}

.about__card h5 {
    font-size: 0.9rem;
}

.about__card small {
    display: inline-block;
    font-size: 0.7rem;
    margin: 0.5rem 0 0.5rem;
    color: var(--color-light);
    line-height: 1.4;
}

.about__content {
    padding-top: 10rem;
}

.about__content p {
    margin: 0 0 0.8rem 0;
    color: var(--color-light);
    line-height: 1.4;
    text-align: justify;
}

.appstore_container {
    display: table-cell;
}

.appstore {
    margin-top: 0.5rem;
    height: 40px;
    width: 120px;
}

.swiper-pagination-bullet-active {
    background-color: var(--color-primary) !important;
}

.swiper-pagination-bullet {
    background-color: var(--color-primary) !important;
}

/* MEDIA QUERIES (MEDIUM) */

@media screen and (max-width: 1024px) {
    .markov__container {
        grid-template-columns: 1fr;
        gap: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .about__content {
        padding-top: 0rem;
    }

    .about__content p {
        margin: 1rem 0 0.5rem;
    }
}

/* MEDIA QUERIES (SMALL) */

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .markov__screenshots {
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 200px;
        height: 450px;
    }

    .markov__screenshots img {
        height: 90%;
        width: auto;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}